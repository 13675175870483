<template>
  <div>
    <!-- 生日禮、高消費卡友生日禮 header -->
    <HeaderBirthday v-if="showHeader"></HeaderBirthday>
    <!-- 試用包 header -->
    <HeaderTrials v-else-if="showHeaderTrials"></HeaderTrials>
    <!-- 一般 header -->
    <Header v-else></Header>
    <router-view></router-view>
    <ScrollTopIcons></ScrollTopIcons>
    <!-- 生日禮 footer -->
    <FooterBirthday v-if="showFooter"></FooterBirthday>
    <!-- 一般、試用包、高消費卡友生日禮 footer -->
    <Footer v-else></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import HeaderBirthday from '@/components/HeaderBirthday.vue'
import HeaderTrials from '@/components/HeaderTrials.vue'
import Footer from '@/components/Footer.vue'
import FooterBirthday from '@/components/FooterBirthday.vue'
import ScrollTopIcons from '@/components/ScrollTopIcons.vue'

export default {
  data () {
    return {
      showHeader: false,
      showFooter: false,
      showHeaderTrials: false
    }
  },
  components: {
    Header,
    HeaderBirthday,
    HeaderTrials,
    Footer,
    FooterBirthday,
    ScrollTopIcons
  },
  methods: {
    //* 生日禮navbar
    setHeaderFooter () {
      if (
        this.$route.fullPath.includes('/birthdaygift/P231208000002') ||
        this.$route.fullPath.includes('/checkoutboard/2024birthdaycheckoutcart') ||
        this.$route.fullPath.includes('/birthdaygift/P230612000001') ||
        this.$route.fullPath.includes('/checkoutboard/2023birthdaycheckoutcart') ||
        this.$route.fullPath.includes('/event/beautyshow')
      ) {
        /* 生日禮 */
        this.showHeader = true
        this.showFooter = true
        this.showHeaderTrials = false
      } else if (
        this.$route.fullPath.includes('/event/trials') ||
        this.$route.fullPath.includes('/event/trialsApply')
      ) {
        /* 試用包 */
        this.showHeaderTrials = true
      } else if (
        this.$route.fullPath.includes('/cardbirthdaygift') ||
        this.$route.fullPath.includes('/checkoutboard/cardbirthdaycheckoutcart')
      ) {
        /* 高消費卡友生日禮 */
        this.showHeader = true
        this.showHeaderTrials = false
      } else {
        /* 官網 */
        this.showHeader = false
        this.showFooter = false
        this.showHeaderTrials = false
      }
    }
  },
  watch: {
    $route () {
      this.setHeaderFooter()
    }
  },
  mounted () {
    this.setHeaderFooter()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/all";
</style>
