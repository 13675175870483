<template>
  <div>
    <div class="spaceTrial"></div>
    <nav class="navbar position-fixed top-0 navbar-light bg-white py-0 w-100 " style="z-index:1000">
      <div class="container">
        <div class="d-flex justify-content-center align-items-center w-100 my-2 my-lg-3">
          <router-link to="/" class="w-40 w-md-30 w-lg-15">
            <img class="" src="@/assets/img/Icons-02.svg" alt="logo">
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.spaceTrial{
  height: 63px;
  @media (min-width: 414px) {
    height: 79px;
  }
  @media (min-width: 768px) {
    height: 86px;
  }
  @media (min-width: 992px) {
    height: 79px;
  }
  @media (min-width: 1024px) {
    height: 88px;
  }
  @media (min-width: 1380px) {
    height: 98px;
  }
}
</style>
